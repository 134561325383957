.login_form{
    width: 50%;
    margin: auto;
}

.login{
    height: 100vh;
}

.login_logo {
	margin-bottom: 3rem;
}

.login_button {
	background: #ffc001;
	cursor: pointer;
	display: inline-block;
	min-height: 1em;
	width: 100%;
	outline: none;
	border: none;
	vertical-align: baseline;
	color: #333333;
	font-family: 'Rubik', 'Roboto', 'Helvetica Neue', Arial, Helvetica, sans-serif;
	margin: 0em 0.25em 0em 0em;
	padding: 0.78571429em 1.5em 0.78571429em;
	text-transform: none;
	text-shadow: none;
	font-weight: bold;
	line-height: 1.8rem;
	font-style: normal;
	text-align: center;
	text-decoration: none;
	font-size: 1.02rem;
	font-weight: 600;
}